import commonUrl from "@/common/commonurl";
import { getOwerUser } from "@/api/service/commonApi";

// IM连接回调
function childCallback() {}

const UserInfo = {
  state: {
    userInfo: {}, // 用户信息
  },
  mutations: {
    // 保存用户信息
    SET_USERINFO(state, info) {
      state.userInfo = info;
    },
  },
  actions: {
    saveUserInfo: ({ commit, rootState }) => {
      return new Promise((resolve, reject) => {
        getOwerUser()
          .then((response) => {
            let data = response.data.result;
            commit("SET_USERINFO", data || {});
            if (response.data.bizCode.code === "0") {
              let dataToken = data.token || "";
              let landline = data.landline || "";
              let companyName = data.companyName || "";
              rootState.IMframe.imFrameSrc =
                `
                    ${commonUrl.imurl}/#/contactshowview?ptype=1&code=${data.id}&name=${companyName}&portraitUri=&remark=${companyName}` +
                `&landline=${landline}&sign=${dataToken}&callback=${encodeURIComponent(
                  childCallback
                )}`;
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default UserInfo;

import Vue from 'vue'

// v-dialogDragWidth: 弹窗宽度拖大 拖小
Vue.directive('dialogDragWidth', {
  bind (el, binding) {
    let dialogW = binding.value.width ? binding.value.width : 1200
    let dialogH = binding.value.height ? binding.value.height : 654
    const content = el.querySelector('.el-dialog')
    el.style.position = 'fixed'
    el.style.width = dialogW + 'px'
    el.style.height = dialogH + 'px'
    el.style.top = '50%'
    el.style.left = '50%'
    el.style.marginLeft = `-${dialogW / 2}px`
    el.style.marginTop = `-${dialogH / 2}px`
    el.style.overflow = 'hidden'
    content.style.marginTop = 0
    content.style.margin = 0
    content.style.width = '100%'
    content.style.height = '100%'

    const move = document.createElement('div')
    move.setAttribute('class', 'el-dialog-move')
    move.setAttribute('style', 'position: absolute; top: 0;left: 0;width: 100%;height: 100%;opacity: 0;display: none;')
    content.appendChild(move)
    const resize = document.createElement('div')
    resize.setAttribute('class', 'el-dialog-resize')
    resize.setAttribute('style', 'position: absolute;width: 15px;height: 15px; right: 0;bottom: 0;cursor: se-resize;')
    content.appendChild(resize)

    var title = el.children[0].children[0]
    if (title) {
      title.style.cursor = 'move'
      title.onmousedown = e => {
        e.stopPropagation ? e.stopPropagation() : e.cancelBubble = true
        // console.log(el.offsetTop)
        el.style.left = el.offsetLeft + 'px'
        el.style.top = el.offsetTop + 'px'
        el.style.margin = '0'
        el.style.marginLeft = '0'
        el.style.marginTop = '0'
        // el.children[0].style.position = 'absolute'
        // if (typeof (node.data.attrs.start) === 'function') {
        //   node.data.attrs.start()
        // }
        let disX = e.clientX - el.offsetLeft
        let disY = e.clientY - el.offsetTop
        let screenW = document.documentElement.clientWidth
        let screenH = document.documentElement.clientHeight
        let maxRight = screenW - el.children[0].offsetWidth
        let maxTop = screenH - el.children[0].offsetHeight
        document.onmousemove = function (e) {
          let l = e.clientX - disX
          let t = e.clientY - disY
          l = l < 0 ? 0 : l
          l = l > maxRight ? maxRight : l
          t = t < 0 ? 0 : t
          t = t > maxTop ? maxTop : t
          el.style.left = l + 'px'
          el.style.top = t + 'px'
        }
        document.onmouseup = () => {
          document.onmousemove = null
          document.onmouseup = null
        }
      }
    }
  },
  update (el, binding) {
    if (binding.value.visible) {
      const dragDom = el
      var dragDomResize = el.querySelector('.el-dialog-resize')

      let dragChange = false
      dragDom.onmousemove = (e) => {
        // var scrollTop = document.documentElement&&document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop
        // var scrollLeft = document.documentElement&&document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft
        let xten = (dragDom.offsetLeft + dragDom.offsetWidth) - e.clientX
        let yten = (dragDom.offsetTop + dragDom.offsetHeight) - e.clientY

        if (xten <= 15 && xten >= 0 && yten <= 15 && yten >= 0) {
          dragChange = true
        } else {
          dragChange = false
        }
      }
      let dialogMove = null
      dragDomResize.onmousedown = (e) => {
        dialogMove = el.querySelector('.el-dialog-move')
        if (dragChange) {
          if (dialogMove) {
            dialogMove.style.display = 'block'
          }

          let oldX = e.clientX
          let oldWidth = dragDom.offsetWidth
          let oldY = e.clientY
          let oldHeight = dragDom.offsetHeight

          document.onmousemove = function (e) {
            e.preventDefault() // 移动时禁用默认事件
            // 计算移动的距离
            const l = e.clientX - oldX
            let width = l >= 0 ? oldWidth + Math.abs(l) : oldWidth - Math.abs(l)

            const h = e.clientY - oldY
            let height = h >= 0 ? oldHeight + Math.abs(h) : oldHeight - Math.abs(h)
            let scrollWidth = document.documentElement.clientWidth
            let scrollHeight = document.documentElement.clientHeight
            width = width <= 300 ? 300 : width
            width = width >= scrollWidth ? scrollWidth : width
            height = height <= 120 ? 120 : height
            height = height >= scrollHeight ? scrollHeight : height
            dragDom.style.width = `${width}px`
            dragDom.style.height = `${height}px`
            if (binding.value.callback) {
              binding.value.callback(height)
            }
          }
        }

        document.onmouseup = function () {
          document.onmousemove = null
          document.onmouseup = null
          if (dialogMove) {
            dialogMove.style.display = 'none'
          }
        }
      }
    } else {
      document.onmousemove = null
      document.onmouseup = null
    }
  }
})

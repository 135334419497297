import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home/home"),
  },
  {
    path: "/shop",
    name: "shop",
    component: () => import("../views/shop/shop"),
  },
  {
    path: "/shopDetail",
    name: "shopDetail",
    component: () => import("../views/shop/shopDetail"),
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("../views/cart/cart"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/system/login"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/system/register"),
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("../views/system/agreement"),
  },
  {
    path: "/qAndA",
    name: "qAndA",
    component: () => import("../views/qAndA/qAndA"),
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import("../views/aboutUs/aboutUs"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/contact/contact"),
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: () => import("../views/system/resetPassword"),
  },
  {
    path: "/payOrder",
    name: "payOrder",
    component: () => import("../views/order/payOrder"),
  },
  {
    path: "/order",
    name: "order",
    component: () => import("../views/order/order"),
  },
  {
    path: "/RFQs",
    name: "RFQs",
    component: () => import("../views/RFQs/RFQs"),
  },
  {
    path: "/RFQs/addRFQs",
    name: "addRFQs",
    component: () => import("../views/RFQs/addRFQs"),
  },
  // {
  //   path: "/epc",
  //   name: "EPC",
  //   component: () => import("../views/EPC/epc"),
  // }
];

const router = new VueRouter({
  routes,
});

export default router;


const IMframe = {
  state: {
    IMVisible: true, // IM窗口打开状态
    IMframe: 'im-iframe', // IM iframe 元素
    imFrameSrc: '' // IM路径
  },
  mutations: {
    SET_IMVISIBLE (state, flag) {
      state.IMVisible = flag
    }
  },
  actions: {
    setIMVisible: ({ commit }, flag) => {
      commit('SET_IMVISIBLE', flag)
    },
    // 打开im窗口，联系客服
    sendContactMsg: ({ commit, state }, data) => {
      commit('SET_IMVISIBLE', true)
      window.frames[state.IMframe].postMessage({ data: data }, '*')
    }
  }
}

export default IMframe

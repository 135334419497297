<template>
  <div id="app">
    <router-view />
    <sideBar></sideBar>
  </div>
</template>
<script>
import SideBar from "@/components/common/sideBar";
export default {
  name: "App",
  components: {
    SideBar,
  },
};
</script>
<style lang="scss" scoped>
* {
  color: #333;
  font-size: 15px;
}
</style>

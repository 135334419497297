let productList = [
  {
    id: '1',
    shortName: 'Tire Valve Sensor',
    categoryName: 'Other Auto Parts',
    name: 'BBmart Valve Stem Tyre Valve for BMW for Audi for Porsche Cayenne for Maserati Alloy Tyre Pressure Monitoring System',
    price: 0.55,
    EssentialDetails: [
      {
        name: 'Model',
        val: 'Q7'
      },
      {
        name: 'Year',
        val: '2015-2015, 2019-2021, 2016-2016, 2015-2016'
      },
      {
        name: 'OE NO.',
        val: '84813000'
      },
      {
        name: 'Car Fitment',
        val: 'Audi'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'Place of Origin',
        val: 'Guangdong, China'
      },
      {
        name: 'OEM',
        val: 'OEM Service'
      },
      {
        name: 'Quality',
        val: 'High-Quality'
      },
      {
        name: 'MOQ',
        val: '1 Pcs'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'Delivery time',
        val: '3-7 Days'
      },
      {
        name: 'Shipping',
        val: 'AIR SEA Express Delivery'
      },
      {
        name: 'Payment',
        val: 'T.Western Union.Paypal.Moneygram'
      },
      {
        name: 'Port',
        val: 'Guangzhou'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '10X7X4 cm'
      },
      {
        name: 'Single gross weight',
        val: '0.050 kg'
      },
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '3 PCS'
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product01-1.png')
      },
      {
        big: require('../../assets/images/product/product01-2.png')
      },
      {
        big: require('../../assets/images/product/product01-3.png')
      },
      {
        big: require('../../assets/images/product/product01-4.png')
      },
      {
        big: require('../../assets/images/product/product01-5.png')
      },
      {
        big: require('../../assets/images/product/product01-6.png')
      }
    ]
  },
  {
    id: '2',
    shortName: 'Sreering Shaft',
    categoryName: 'Other Auto Steering Parts',
    name: 'BBmart Auto Parts Steering Shaft Steering Knuckle for Audi A6 OE 4GD419753 Wholesale Factory Direct Price',
    price: 52,
    EssentialDetails: [
      {
        name: 'Model',
        val: 'A6'
      },
      {
        name: 'Year',
        val: '2015-2018, 2015-2016, 2016-2016'
      },
      {
        name: 'OE NO.',
        val: '4GD419753'
      },
      {
        name: 'Car Fitment',
        val: 'Audi'
      },
      {
        name: 'Type',
        val: 'Steering Knuckle'
      },
      {
        name: 'Warranty',
        val: '2 Years'
      },
      {
        name: 'Place of Origin',
        val: 'China'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'Car Model',
        val: 'for Audi A6'
      },
      {
        name: 'OEM',
        val: 'Accepted'
      },
      {
        name: 'Quality',
        val: 'High-Quality'
      },
      {
        name: 'MOQ',
        val: '1 PCS'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'Shipping',
        val: 'SEA AIR DHL FEDEX EMS'
      },
      {
        name: 'Port',
        val: 'Guangzhou'
      },
      {
        name: 'Service',
        val: 'Professional Service'
      },
      {
        name: 'PAYMENT',
        val: 'TT.paypal.PP.Trade Assurance'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '50X10X10 cm'
      },
      {
        name: 'Single gross weight',
        val: '8.000 kg'
      },
      {
        name: 'Package Type',
        val: 'Carton'
      }
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '3 PCS'
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product02-2.png')
      },
      {
        big: require('../../assets/images/product/product02-3.png')
      },
      {
        big: require('../../assets/images/product/product02-4.png')
      },
      {
        big: require('../../assets/images/product/product02-5.png')
      },
      {
        big: require('../../assets/images/product/product02-6.png')
      }
    ]
  },
  {
    id: '3',
    shortName: 'Crankcase Oil Water Separator',
    categoryName: 'Other Auto Parts',
    name: 'BBmart Auto Parts Crankcase Oil Water Separator Fit PCV Valve Cover Assembly For VW Scirocco/Sharan OE 06H 103 495AJ',
    price: 17,
    EssentialDetails: [
      {
        name: 'Model',
        val: 'SHARAN, Scirocco'
      },
      {
        name: 'Year',
        val: '2015-2016, 2014-2016, 2015-2016, 2011-2016, 2011-2015'
      },
      {
        name: 'OE NO.',
        val: '06H 103 495AJ'
      },
      {
        name: 'Car Fitment',
        val: 'VW'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'Place of Origin',
        val: 'Guangdong, China'
      },
      {
        name: 'OEM',
        val: 'OEM Service'
      },
      {
        name: 'Quality',
        val: 'High-Quality'
      },
      {
        name: 'MOQ',
        val: '1 Piece'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'Shipping',
        val: 'AIR SEA Express Delivery'
      },
      {
        name: 'Payment',
        val: 'Union.Paypal TT'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '25X22X10 cm'
      },
      {
        name: 'Single gross weight',
        val: '5.000 kg'
      }
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '1 PCS'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product03-1.png')
      },
      {
        big: require('../../assets/images/product/product03-2.png')
      },
      {
        big: require('../../assets/images/product/product03-3.png')
      },
      {
        big: require('../../assets/images/product/product03-5.png')
      },
      {
        big: require('../../assets/images/product/product03-6.png')
      }
    ]
  },
  {
    id: '4',
    shortName: 'Ignition Coils',
    categoryName: 'Ignition Coils',
    name: 'BBmart Auto Parts Ignition System Engine Ignition Coil for Porsche Cayenne Audi Q3 VW Golf OE 94860210421 022905100N 04E905110C',
    price: 16,
    EssentialDetails: [
      {
        name: 'Model',
        val: 'CARRERA GT (980), CAYENNE (9PA)'
      },
      {
        name: 'Engine',
        val: 'Turbo 4.5, 5.7'
      },
      {
        name: 'Year',
        val: '2002-2010, 2003-2006'
      },
      {
        name: 'OE NO.',
        val: '94860210421'
      },
      {
        name: 'Car Fitment',
        val: 'PORSCHE'
      },
      {
        name: 'Reference NO.',
        val: '10698/1, 85.30454-2, 85.30454A2, 8010698/1'
      },
      {
        name: 'Size',
        val: 'standard'
      },
      {
        name: 'Warranty',
        val: '2 Years'
      },
      {
        name: 'Place of Origin',
        val: 'Guangdong, China'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'Car Model',
        val: 'for Porsche Cayenne'
      },
      {
        name: 'OEM',
        val: 'OEM Service'
      },
      {
        name: 'Quality',
        val: 'High-Quality'
      },
      {
        name: 'MOQ',
        val: '1 Pcs'
      },
      {
        name: 'Delivery time',
        val: '3-7 Days'
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'Payment Term',
        val: 'TT.Western Union.Paypal.Tarde Assuarance'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '25X22X10 cm'
      },
      {
        name: 'Single gross weight',
        val: '5.000 kg'
      }
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '3 PCS'
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product04-1.png')
      },
      {
        big: require('../../assets/images/product/product04-2.png')
      },
      {
        big: require('../../assets/images/product/product04-3.png')
      },
      {
        big: require('../../assets/images/product/product04-4.png')
      },
      {
        big: require('../../assets/images/product/product04-5.png')
      },
      {
        big: require('../../assets/images/product/product04-6.png')
      }
    ]
  },
  {
    id: '5',
    shortName: 'Radiator',
    categoryName: 'Radiators',
    name: 'BBmart OEM Auto Parts Radiator 95510614201 For PORSCHE CAYENNE (9PA) 2002-2010',
    price: 66,
    EssentialDetails: [
      {
        name: 'Model',
        val: 'CAYENNE (9PA)'
      },
      {
        name: 'Engine',
        val: '3.2, Turbo 4.5, 3.6, S 4.8, GTS 4.8, Turbo S 4.8'
      },
      {
        name: 'Year',
        val: '2002-2010'
      },
      {
        name: 'OE NO.',
        val: '955 106 142 01'
      },
      {
        name: 'Car Fitment',
        val: 'PORSCHE'
      },
      {
        name: 'Reference NO.',
        val: 'VW2214, 58002214, 07.40.083, 53798, DRM02026'
      },
      {
        name: 'Warranty',
        val: '2 Years'
      },
      {
        name: 'Place of Origin',
        val: 'Guangdong, China'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'Car Model',
        val: 'For PORSCHE CAYENNE (9PA) 2002-2010'
      },
      {
        name: 'OEM',
        val: 'Accept'
      },
      {
        name: 'MOQ',
        val: '1 Piece'
      },
      {
        name: 'Shipment',
        val: 'Air Sea Express Delivery'
      },
      {
        name: 'Payment Method',
        val: 'Trade Assurance'
      },
      {
        name: 'Product Name',
        val: 'Water Radiator'
      },
      {
        name: 'Car Make',
        val: 'For PORSCHE CAYENNE (9PA) 2002-2010'
      },
      {
        name: 'OEM NO',
        val: '955 106 142 01'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '110X85X8 cm'
      },
      {
        name: 'Single gross weight',
        val: '10.000 kg'
      }
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '1 PCS'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product05-1.png')
      }
    ]
  },
  {
    id: '6',
    shortName: 'Brake Disc',
    categoryName: 'Brake Discs',
    name: 'BBmart Auto Spare Car Parts Brake Disc for Porsche Cayenne 2010 OE 95835140301 Wholesale Factory Direct Price',
    price: 75.3,
    EssentialDetails: [
      {
        name: 'Model',
        val: 'CAYENNE (92A)'
      },
      {
        name: 'Year',
        val: '2010-'
      },
      {
        name: 'OE NO.',
        val: '95835140301'
      },
      {
        name: 'Car Fitment',
        val: 'PORSCHE'
      },
      {
        name: 'Reference NO.',
        val: '8DD 355 123-201'
      },
      {
        name: 'Warranty',
        val: '2 Years'
      },
      {
        name: 'Place of Origin',
        val: 'China'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'OEM',
        val: 'Accepted'
      },
      {
        name: 'Quality',
        val: 'High-Quality'
      },
      {
        name: 'MOQ',
        val: '1 PCS'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'Shipping',
        val: 'SEA AIR DHL FEDEX EMS'
      },
      {
        name: 'Port',
        val: 'Guangzhou'
      },
      {
        name: 'Service',
        val: 'Professional Service'
      },
      {
        name: 'PAYMENT',
        val: 'TT.paypal.PP.Trade Assurance'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '40X40X20 cm'
      },
      {
        name: 'Single gross weight',
        val: '11.000 kg'
      },
      {
        name: 'Package Type',
        val: 'Carton'
      }
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '3 PCS'
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product06-1.png')
      },
      {
        big: require('../../assets/images/product/product06-2.png')
      },
      {
        big: require('../../assets/images/product/product06-3.png')
      }
    ]
  },
  {
    id: '7',
    shortName: 'Shock Absorber',
    categoryName: 'Shock Absorbers',
    name: 'BBmart Wholesale Factory Direct Price Auto Parts Rear Left Air Suspension Shock Absorber for Audi A6 4Z7616051 4Z7 616 051',
    price: 277.1,
    EssentialDetails: [
      {
        name: 'Model',
        val: 'A6allroad'
      },
      {
        name: 'Year',
        val: '2000-2005'
      },
      {
        name: 'OE NO.',
        val: '4Z7616051'
      },
      {
        name: 'Car Fitment',
        val: 'Audi'
      },
      {
        name: 'Warranty',
        val: '2 Years'
      },
      {
        name: 'Place of Origin',
        val: 'China'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'Car Model',
        val: 'for Audi A6'
      },
      {
        name: 'OEM',
        val: 'Accepted'
      },
      {
        name: 'Quality',
        val: 'High-Quality'
      },
      {
        name: 'MOQ',
        val: '1 PCS'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'Shipping',
        val: 'SEA AIR DHL FEDEX EMS'
      },
      {
        name: 'Port',
        val: 'Guangzhou'
      },
      {
        name: 'Service',
        val: 'Professional Service'
      },
      {
        name: 'PAYMENT',
        val: 'TT.paypal.PP.Trade Assurance'
      },
      {
        name: 'Engine',
        val: '-'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '80X20X10 cm'
      },
      {
        name: 'Single gross weight',
        val: '15.000 kg'
      },
      {
        name: 'Package Type',
        val: 'Carton'
      }
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '3 PCS'
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product07-1.png')
      },
      {
        big: require('../../assets/images/product/product07-2.png')
      },
      {
        big: require('../../assets/images/product/product07-3.png')
      },
      {
        big: require('../../assets/images/product/product07-4.png')
      },
      {
        big: require('../../assets/images/product/product07-5.png')
      }
    ]
  },
  {
    id: '8',
    shortName: 'Engine Intake',
    categoryName: 'Intake Manifold',
    name: 'BBmart Auto Spare Car Parts Air Suspension Engine Intake Manifold for Audi A6 A7 OE 06E 133 201 R 06E133201R',
    price: 218.5,
    EssentialDetails: [
      {
        name: 'Model',
        val: 'A6 (4G2, 4GC, C7)'
      },
      {
        name: 'Year',
        val: '2010-'
      },
      {
        name: 'Engine',
        val: '2.0 TDI'
      },
      {
        name: 'OE NO.',
        val: '06E133201R'
      },
      {
        name: 'Car Fitment',
        val: 'Audi'
      },
      {
        name: 'Size',
        val: 'standard'
      },
      {
        name: 'Warranty',
        val: '2 Years'
      },
      {
        name: 'Place of Origin',
        val: 'Guangdong, China'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'Car Model',
        val: 'for Audi C7'
      },
      {
        name: 'MOQ',
        val: '1 PCS'
      },
      {
        name: 'Quality',
        val: 'High-Quality'
      },
      {
        name: 'OEM',
        val: 'support'
      },
      {
        name: 'Packing',
        val: 'Carton Packing'
      },
      {
        name: 'Shipping',
        val: 'SEA AIR DHL FEDEX EMS'
      },
      {
        name: 'Delivery time',
        val: '3-7 Days'
      },
      {
        name: 'Port',
        val: 'Guangzhou'
      },
      {
        name: 'PAYMENT',
        val: 'TT.paypal.Western Union.Trade Assurance'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '40X40X40 cm'
      },
      {
        name: 'Single gross weight',
        val: '8.000 kg'
      }
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '3 PCS'
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product08-1.png')
      }
    ]
  },
  {
    id: '9',
    shortName: 'Coolant Hose',
    categoryName: 'Other Cooling Systems',
    name: 'BBmart OEM Auto Spare Car Parts 06B 121 058 Engine Coolant Hose 06B121058 for Audi A4 B5 VW PASSAT cc 3b2 3b3 1.8 T quattro',
    price: 6.6,
    EssentialDetails: [
      {
        name: 'OE NO.',
        val: '06B 121 058'
      },
      {
        name: 'Model',
        val: 'A4 (8D2, B5), A4 Avant (8D5, B5), PASSAT (3B3), PASSAT (3B2)'
      },
      {
        name: 'Year',
        val: '1994-2001, 1994-2001, 1996-2001, 2000-2005'
      },
      {
        name: 'Engine',
        val: '1.8 T quattro, 1.8 T quattro, 1.8 t, 1.8 t, 1.8 t'
      },
      {
        name: 'Car Fitment',
        val: 'VW, Audi'
      },
      {
        name: 'Reference NO.',
        val: '119 121 0164, 1114301700, V10-3911'
      },
      {
        name: 'Type',
        val: 'Water Pump'
      },
      {
        name: 'Warranty',
        val: '2 Years'
      },
      {
        name: 'Place of Origin',
        val: 'Guangdong, China'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'Size',
        val: 'Standard'
      },
      {
        name: 'Car Model',
        val: 'for Audi VW PASSAT'
      },
      {
        name: 'Packing',
        val: 'Carton Packing'
      },
      {
        name: 'MOQ',
        val: '1 Piece'
      },
      {
        name: 'OEM',
        val: 'OEM Services Provided'
      },
      {
        name: 'Quality',
        val: 'High-Quality'
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'Service',
        val: '24 Hours Customer Services'
      },
      {
        name: 'Port',
        val: 'Guangzhou Shenzhen'
      },
      {
        name: 'Payment',
        val: 'TT .WESTERN UNION .PAYPAL'
      },
      {
        name: 'Shipping',
        val: 'SEA AIR DHL FEDEX UPS'
      },
      {
        name: 'Application',
        val: 'Automotive Cooling System'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '40X40X40 cm'
      },
      {
        name: 'Single gross weight',
        val: '8.000 kg'
      }
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '1 PCS'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product09-1.png')
      },
      {
        big: require('../../assets/images/product/product09-2.png')
      },
      {
        big: require('../../assets/images/product/product09-3.png')
      }
    ]
  },
  {
    id: '10',
    shortName: 'Engine Oil Cooler',
    categoryName: 'Other Cooling Systems',
    name: 'BBmart Auto Spare Car Parts Engine Transmission Oil Cooler Radiator for VW OE 3C0 317 037A 3C0317037A',
    price: 33,
    EssentialDetails: [
      {
        name: 'OE NO.',
        val: '3C0 317 037A'
      },
      {
        name: 'Model',
        val: 'PASSAT Variant (3C5), PASSAT (3C2)'
      },
      {
        name: 'Year',
        val: '2005-2010, 2005-2011'
      },
      {
        name: 'Engine',
        val: '2.0 FSI, 2.0 FSI'
      },
      {
        name: 'Car Fitment',
        val: 'VW'
      },
      {
        name: 'Reference NO.',
        val: '8095077'
      },
      {
        name: 'Type',
        val: 'Radiator'
      },
      {
        name: 'Warranty',
        val: '2 Years'
      },
      {
        name: 'Place of Origin',
        val: 'Guangdong, China'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'Size',
        val: 'Standard'
      },
      {
        name: 'Car Model',
        val: 'for VW'
      },
      {
        name: 'Packing',
        val: 'Carton Packing'
      },
      {
        name: 'MOQ',
        val: '1 Piece'
      },
      {
        name: 'OEM',
        val: 'OEM Services Provided'
      },
      {
        name: 'Quality',
        val: 'High-Quality'
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'Service',
        val: '24 Hours Customer Services'
      },
      {
        name: 'Port',
        val: 'Guangzhou Shenzhen'
      },
      {
        name: 'Payment',
        val: 'TT .WESTERN UNION .PAYPAL'
      },
      {
        name: 'Shipping',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '30X10X10 cm'
      },
      {
        name: 'Single gross weight',
        val: '8.000 kg'
      }
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '1 PCS'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product10-1.png')
      }
    ]
  },
  {
    id: '11',
    shortName: 'Engine Air Filter',
    categoryName: 'Air Filters',
    name: 'BBmart Factory Low Price Auto Spare Car Parts Engine Air Filter For Audi Q7 Q8 VW Touareg 4M0133843C',
    price: 9.9,
    EssentialDetails: [

      {
        name: 'Model',
        val: 'Q7 (4MB)'
      },
      {
        name: 'Engine',
        val: '3.0 45 TFSI quattro'
      },
      {
        name: 'Year',
        val: '2015-'
      },
      {
        name: 'OE NO.',
        val: '4M0133843C'
      },
      {
        name: 'Car Fitment',
        val: 'Audi'
      },
      {
        name: 'Reference NO.',
        val: '154072402674, C38011, LX 3233/6, E1338L'
      },
      {
        name: 'Warranty',
        val: '2 Years'
      },
      {
        name: 'Place of Origin',
        val: 'China'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'Car Model',
        val: 'For Audi'
      },
      {
        name: 'OEM',
        val: 'Accepted'
      },
      {
        name: 'Quality',
        val: 'High-Quality'
      },
      {
        name: 'MOQ',
        val: '1 PCS'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'Shipping',
        val: 'SEA AIR DHL FEDEX EMS'
      },
      {
        name: 'Port',
        val: 'Guangzhou'
      },
      {
        name: 'Service',
        val: 'Professional Service'
      },
      {
        name: 'PAYMENT',
        val: 'TT.paypal.PP.Trade Assurance'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '30X30X30 cm'
      },
      {
        name: 'Single gross weight',
        val: '15.000 kg'
      }
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '3 PCS'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product11-1.png')
      }
    ]
  },
  {
    id: '12',
    shortName: 'Engine Air Filter For Audi A8 S8',
    categoryName: 'Air Filters',
    name: 'BBmart Factory Low Price Auto Spare Car Parts Engine Air Filter For Audi A8 S8 4N0129620C 4N0 129 620 C',
    price: 5.1,
    EssentialDetails: [
      {
        name: 'Model',
        val: 'A8, S8'
      },
      {
        name: 'Year',
        val: '2013-2016, 2017-2019'
      },
      {
        name: 'Engine',
        val: '4.0t, 50 TDI quattro'
      },
      {
        name: 'OE NO.',
        val: '4N0 129 620 C'
      },
      {
        name: 'Car Fitment',
        val: 'Audi'
      },
      {
        name: 'Size',
        val: '4N0129620C'
      },
      {
        name: 'Warranty',
        val: '2 Years'
      },
      {
        name: 'Place of Origin',
        val: 'Guangdong, China'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'Car Model',
        val: 'For Audi'
      },
      {
        name: 'OEM',
        val: 'Accepted'
      },
      {
        name: 'Quality',
        val: 'High-Quality'
      },
      {
        name: 'MOQ',
        val: '1 PCS'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'Shipping',
        val: 'SEA AIR DHL FEDEX EMS'
      },
      {
        name: 'Port',
        val: 'Guangzhou'
      },
      {
        name: 'Service',
        val: 'Professional Service'
      },
      {
        name: 'PAYMENT',
        val: 'TT.paypal.PP.Trade Assurance'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '30X30X30 cm'
      },
      {
        name: 'Single gross weight',
        val: '15.000 kg'
      }
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '3 PCS'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product12-1.png')
      }
    ]
  },
  {
    id: '13',
    shortName: 'Engine Oil filter For Audi A4 A6 A8',
    categoryName: 'Oil Filters',
    name: 'BBmart Auto Spare Car Parts Engine Oil filter For Audi A4 A6 A8 OE 078115561J 078 115 561 J',
    price: 5.7,
    EssentialDetails: [
      {
        name: 'Model',
        val: 'ALLROAD (4BH, C5), A6 (4B2, C5), A4 Avant (8E5, B6), A6 Avant (4B5, C5), A4 (8E2, B6), A4 (8D2, B5), A6 (4F2, C6), A4 Avant (8D5, B5), A6 Avant (4A5, C4), A4 Convertible (8H7, B6, 8HE, B7), CABRIOLET (8G7, B4), A6 (4A2, C4), 80 (8C2, B4), PASSAT Variant (3B6), PASSAT (3B3)'
      },
      {
        name: 'Engine',
        val: '2.8, 2.8 quattro, 3.0 quattro, 2.8, 2.8 quattro, 3.0 quattro, 2.8, 2.8, 2.8 quattro, 2.8, 2.8 quattro, 2.8, 2.8 quattro, 3.0 quattro, 3, 2.8 quattro, 2.8, 3.0 quattro, 3, 3.0 quattro, 3, 2.7 T quattro, 3, 3.0 quattro, 2.8 quattro, 2.7 T quattro, 2.8, 2.8 4motion, 2.8 4motion'
      },
      {
        name: 'Year',
        val: '1994-1997, 1994-2001, 1991-1994, 1994-1997, 2002-2009, 2000-2004, 1994-2001, 1997-2005, 2004-2011, 1997-2005, 2001-2004, 1991-2000, 2000-2005, 2000-2005, 2000-2005'
      },
      {
        name: 'OE NO.',
        val: '078115561J'
      },
      {
        name: 'Reference NO.',
        val: '451103256, FX0131, 451103313, PH453, BFO4172, 350OS, 2346500, FO266, SP979, Z1417, L25401'
      },
      {
        name: 'Warranty',
        val: '2 Years'
      },
      {
        name: 'Place of Origin',
        val: 'China'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'OEM',
        val: 'Accepted'
      },
      {
        name: 'Quality',
        val: 'High-Quality'
      },
      {
        name: 'MOQ',
        val: '1 PCS'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'Shipping',
        val: 'SEA AIR DHL FEDEX EMS'
      },
      {
        name: 'Port',
        val: 'Guangzhou'
      },
      {
        name: 'Service',
        val: 'Professional Service'
      },
      {
        name: 'PAYMENT',
        val: 'TT.paypal.PP.Trade Assurance'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '30X30X30 cm'
      },
      {
        name: 'Single gross weight',
        val: '15.000 kg'
      }
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '3 PCS'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product13-1.png')
      }
    ]
  },
  {
    id: '14',
    shortName: 'Engine Oil filter For VW Passat Polo Lavida',
    categoryName: 'Oil Filters',
    name: 'BBmart Auto Spare Car Parts Engine Oil filter For VW Passat Polo Lavida OE 06A115561E 06A 115 561 E',
    price: 1,
    EssentialDetails: [
      {
        name: 'Model',
        val: 'GOLF III Cabriolet (1E7), SHARAN (7M8, 7M9, 7M6), GOLF III (1H1), PASSAT Variant (3A5, 35I), BORA (1J2), JETTA IV (162, 163), VENTO (1H2), GOLF IV Cabriolet (1E7), PASSAT (3A2, 35I), BORA Variant (1J6), GOLF IV Variant (1J5), NEW BEETLE Convertible (1Y7), GOLF IV (1J1), NEW BEETLE (9C1, 1C1), PASSAT (3B3)'
      },
      {
        name: 'Engine',
        val: '1.8 T 20V, 2, 1.8, 2, 1.8 t, 2, 2, 1.8, 2, 2.0 16V, 2, 1.8 t, 2.0 4motion, 1.8 t, 1.8 t, 2, 2, 2, 2, 2, 2.0 16V'
      },
      {
        name: 'Year',
        val: '1995-2010, 1991-1998, 1998-2005, 1999-2005, 1999-2006, 1991-1998, 1997-2005, 2010-, 1998-2002, 1988-1997, 1998-2010, 2002-2010, 1993-1998, 1988-1997, 2000-2005'
      },
      {
        name: 'OE NO.',
        val: '06A115561E'
      },
      {
        name: 'Car Fitment',
        val: 'VW'
      },
      {
        name: 'Reference NO.',
        val: 'S3291R, 50547300, 50547301, 451103228'
      },
      {
        name: 'Warranty',
        val: '2 Years'
      },
      {
        name: 'Place of Origin',
        val: 'China'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'OEM',
        val: 'Accepted'
      },
      {
        name: 'Quality',
        val: 'High-Quality'
      },
      {
        name: 'MOQ',
        val: '1 PCS'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'Shipping',
        val: 'SEA AIR DHL FEDEX EMS'
      },
      {
        name: 'Port',
        val: 'Guangzhou'
      },
      {
        name: 'Service',
        val: 'Professional Service'
      },
      {
        name: 'PAYMENT',
        val: 'TT.paypal.PP.Trade Assurance'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '30X30X30 cm'
      },
      {
        name: 'Single gross weight',
        val: '10.000 kg'
      }
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '3 PCS'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product14-1.png')
      }
    ]
  },
  {
    id: '15',
    shortName: 'Engine Oil filter For Audi A1 A3 S3 VW Bora Golf Passat',
    categoryName: 'Oil Filters',
    name: 'BBmart Auto Spare Car Parts Engine Oil filter For Audi A1 A3 S3 VW Bora Golf Passat OE 03C115561B 03C 115 561 B',
    price: 7,
    EssentialDetails: [
      {
        name: 'Model',
        val: 'A1 (8X1, 8XK), A3 (8P1), IBIZA IV ST (6J8, 6P8), LEON (1P1), POLO (6R, 6C), TIGUAN (5N_), JETTA IV (162, 163)'
      },
      {
        name: 'Engine',
        val: '1.4 GTI, 1.2 TSI, 1.4 TSI, 1.4 TFSI, 1.4 TFSI, 1.4 TSI Hybrid, 1.4 TSI, 1.4 TSI'
      },
      {
        name: 'Year',
        val: '2007-, 2009-, 2003-2012, 2010-, 2010-, 2010-, 2005-2012'
      },
      {
        name: 'OE NO.',
        val: '03C115561B'
      },
      {
        name: 'Car Fitment',
        val: 'Seat, VW, Audi'
      },
      {
        name: 'Reference NO.',
        val: '30938599, SM5085, W71290, 311110073, OC5934'
      },
      {
        name: 'Warranty',
        val: '2 Years'
      },
      {
        name: 'Place of Origin',
        val: 'China'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'OEM',
        val: 'Accepted'
      },
      {
        name: 'Quality',
        val: 'High-Quality'
      },
      {
        name: 'MOQ',
        val: '1 PCS'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'Shipping',
        val: 'SEA AIR DHL FEDEX EMS'
      },
      {
        name: 'Port',
        val: 'Guangzhou'
      },
      {
        name: 'Service',
        val: 'Professional Service'
      },
      {
        name: 'PAYMENT',
        val: 'TT.paypal.PP.Trade Assurance'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '30X30X30 cm'
      },
      {
        name: 'Single gross weight',
        val: '10.000 kg'
      }
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '3 PCS'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product15-1.png')
      }
    ]
  },
  {
    id: '16',
    shortName: 'Ignition Coil For VW GOLF JETTA Polo 06A905097',
    categoryName: 'Ignition Coils',
    name: 'BBmart Auto Parts Ignition Coil For VW GOLF JETTA Polo 06A905097 Wholesale Factory Direct Price',
    price: 53.7,
    EssentialDetails: [
      {
        name: 'Model',
        val: 'JETTA IV (162, 163), NEW BEETLE (9C1, 1C1), BORA Variant (1J6), GOLF IV Variant (1J5), NEW BEETLE Convertible (1Y7), BORA (1J2), GOLF IV (1J1)'
      },
      {
        name: 'Engine',
        val: '2, 2, 2, 2.0 4motion, 2, 2, 2'
      },
      {
        name: 'Year',
        val: '1998-2005, 1999-2005, 1999-2006, 1997-2005, 2010-, 1998-2010, 2002-2010'
      },
      {
        name: 'OE NO.',
        val: '06A905097'
      },
      {
        name: 'Car Fitment',
        val: 'VW'
      },
      {
        name: 'Reference NO.',
        val: '8530172, JM5044, PCI1188, 03SKV054, 060810192010, 0986221049, 8010374, V10700046, GN1038312B1, WG1012203, 1008850000, 30929319'
      },
      {
        name: 'Warranty',
        val: '2 Years'
      },
      {
        name: 'Place of Origin',
        val: 'China'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'Car Model',
        val: 'For VW'
      },
      {
        name: 'OEM',
        val: 'Accepted'
      },
      {
        name: 'Quality',
        val: 'High-Quality'
      },
      {
        name: 'MOQ',
        val: '1 PCS'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'Shipping',
        val: 'SEA AIR DHL FEDEX EMS'
      },
      {
        name: 'Port',
        val: 'Guangzhou'
      },
      {
        name: 'Service',
        val: 'Professional Service'
      },
      {
        name: 'PAYMENT',
        val: 'TT.paypal.PP.Trade Assurance'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '15X15X10 cm'
      },
      {
        name: 'Single gross weight',
        val: '1.500 kg'
      }
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '3 PCS'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product16-1.png')
      }
    ]
  },
  {
    id: '17',
    shortName: 'ignition system Engine Ignition Coil for Porsche Panamera AUDI VW',
    categoryName: 'Ignition Coils',
    name: 'BBmart Auto Parts ignition system Engine Ignition Coil for Porsche Panamera AUDI VW OE 996 602 104 02 99660210402',
    price: 16,
    EssentialDetails: [
      {
        name: 'Model',
        val: '911 Targa (991), 911 (997), Boxster Spyder (987), CAYMAN (987), CAYMAN (981), BOXSTER (981), 911 (991), 911 Convertible (997), 911 Convertible (991), BOXSTER (987), 911 Targa (997)'
      },
      {
        name: 'Engine',
        val: '3.4 Carrera, 3.8 Carrera 4S, 3.8 Turbo, 3.4 Carrera 4, 3.8 Carrera 4S / 4 GTS, 3.8 GT3, 3.8 Carrera S / GTS, 3.8 Carrera S, 3.8 Turbo S, 3.6 Carrera 4, 3.8 Carrera 4S / 4 GTS, 3.4 Carrera 4, 3.8 Carrera 4S, 3.8 Carrera GTS, 3.8 Turbo, 3.8 Turbo S, 3.8 Carrera S, 4.0 GT3 RS, 3.8 Carrera 4 GTS, 3.8 GT3, 3.6 Carrera 4, 3.6 Carrera, 3.8 Carrera 4S, S 3.4, 2.9, 3.8 Carrera S / GTS, 3.8 Carrera 4S / 4 GTS, 3.4 Carrera 4, 3.4 Carrera, 3.8 Carrera 4S, 3.8 Turbo S, 3.8 Carrera S, 3.8 Turbo, 3.8 Carrera 4 GTS, 3.6 Carrera 4, 3.6 Carrera, 3.8 Carrera GTS, 3.8 Turbo, 3.8 Carrera 4S, 3.8 Turbo S, 3.8 Carrera S, S 3.4, 2.7, 3.8 GT4, GTS 3.4, 3.4, 2.9, GTS 3.4, 2.7, S 3.4'
      },
      {
        name: 'Year',
        val: '2005-2012, 2004-2011, 2012-, 2013-, 2014-, 2012-, 2011-, 2006-2012, 2005-2013, 2004-2012, 2007-2011'
      },
      {
        name: 'OE NO.',
        val: '99660210402'
      },
      {
        name: 'Car Fitment',
        val: 'PORSCHE'
      },
      {
        name: 'Reference NO.',
        val: 'ZSE 052, 178-8479, V45-70-0003'
      },
      {
        name: 'Size',
        val: 'standard'
      },
      {
        name: 'Warranty',
        val: '2 Years'
      },
      {
        name: 'Place of Origin',
        val: 'Guangdong, China'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'Car Model',
        val: 'for Porsche Panamera'
      },
      {
        name: 'OEM',
        val: 'OEM Service'
      },
      {
        name: 'Quality',
        val: 'High-Quality'
      },
      {
        name: 'MOQ',
        val: '1 PCS'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'Shipping term',
        val: 'SEA AIR DHL FEDEX UPS'
      },
      {
        name: 'Delivery time',
        val: '3-7 Days'
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'Payment Term',
        val: 'TT.Western Union.Paypal.Tarde Assuarance'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '20X10X10 cm'
      },
      {
        name: 'Single gross weight',
        val: '0.500 kg'
      }
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '3 PCS'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product17-1.png')
      }
    ]
  },
  {
    id: '18',
    shortName: 'Engine Oil filter For Audi A1 A3 A3 VW GOLF 7',
    categoryName: 'Oil Filters',
    name: 'BBmart Auto Spare Car Parts Engine Oil filter For Audi A1 A3 A3 VW GOLF 7 OE 04E115561H 04E 115 561 H',
    price: 16,
    EssentialDetails: [
      {
        name: 'Model',
        val: 'A1 (8X1, 8XK), A3 (8V1, 8VK), Q3 (8UB, 8UG), A3 Limousine (8VS, 8VM), TOLEDO IV (KG3), LEON (5F1), LEON ST (5F8), IBIZA IV SPORTCOUPE (6J1, 6P5), JETTA IV (162, 163), GOLF VII (5G1, BQ1, BE1, BE2)'
      },
      {
        name: 'Engine',
        val: '1.4 TFSI, 1.4 TFSI, 1.4 TFSI, 1.4 TFSI, 1.4 TSI, 1.4 TSI, 1.4 TSI Hybrid, 1.6, 1.4 TSI, 1.4 TSI, 1.4 TSI, 1.6'
      },
      {
        name: 'Year',
        val: '2011-, 2012-, 2010-, 2013-, 2012-, 2010-, 2012-, 2008-, 2012-, 2013-'
      },
      {
        name: 'OE NO.',
        val: '04E 115 561 H'
      },
      {
        name: 'Car Fitment',
        val: 'Seat, VW, Audi'
      },
      {
        name: 'Reference NO.',
        val: '1510086, 50014678, DOFC977/1, OF-4450, PF4223, 132953, DL1299, S 3575 R, 153071760755, OC 1449, A210743, PP253, FH1184, 26-0874, LFOS293, O-1011, WG1726398, 61090, 712/95-OF-PCS-MS, LVFL791'
      },
      {
        name: 'Warranty',
        val: '2 Years'
      },
      {
        name: 'Place of Origin',
        val: 'China'
      },
      {
        name: 'Brand Name',
        val: 'BBmart'
      },
      {
        name: 'Car Model',
        val: 'For Audi'
      },
      {
        name: 'OEM',
        val: 'Accepted'
      },
      {
        name: 'Quality',
        val: 'High-Quality'
      },
      {
        name: 'MOQ',
        val: '1 PCS'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'Shipping',
        val: 'SEA AIR DHL FEDEX EMS'
      },
      {
        name: 'Port',
        val: 'Guangzhou'
      },
      {
        name: 'Service',
        val: 'Professional Service'
      },
      {
        name: 'PAYMENT',
        val: 'TT.paypal.PP.Trade Assurance'
      }
    ],
    Packagingelivery: [
      {
        name: 'Selling Units',
        val: 'Single item'
      },
      {
        name: 'Single package size',
        val: '30X30X30 cm'
      },
      {
        name: 'Single gross weight',
        val: '10.000 kg'
      }
    ],
    specification: [
      {
        name: 'Service',
        val: 'Service 24 Hours Customer Service'
      },
      {
        name: 'Packing',
        val: 'Carton'
      },
      {
        name: 'MOQ',
        val: '3 PCS'
      },
      {
        name: 'OEM service',
        val: "Customer's Demand"
      },
      {
        name: 'Brand',
        val: 'BBmart'
      },
      {
        name: 'Payment',
        val: 'TT.paypal.Western Union.Trade Assurance'
      },
      {
        name: 'SHIPPING',
        val: 'SEA AIR DHL FEDEX UPS'
      }
    ],
    urlList: [
      {
        big: require('../../assets/images/product/product18-1.png')
      }
    ]
  }
]

export default {
  productList
}
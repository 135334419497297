import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/reset.css";
import "./assets/css/common.css";
import "./assets/iconFont/iconfont.css";
import productList from "./assets/js/product";
import utils from "./utils/util";
import "./assets/js/directives.js"; // 自定义指令
import http from "./api/http";

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$productList = productList;
Vue.prototype.$utils = utils;
Vue.prototype.$axios = http;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

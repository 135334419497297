import request from "@/utils/request";
import qs from "qs";
// 获取客服用户信息
export function getStaffDetail(query) {
  return request({
    baseURL: "/benben-dubbo-im",
    method: "POST",
    url: "/im/getStaffDetail",
    data: query,
  });
}
// 获取当前用户信息
export function getOwerUser(query) {
  return request({
    method: "POST",
    url: "/foreign/user/getOwerUser",
    data: qs.stringify(query),
  });
}
// 购物车列表
export function getCartList(query) {
  return request({
    method: "POST",
    url: "/foreign/cart/cartList",
    data: qs.stringify(query),
  });
}
// 加入购物车
export function createCrat(query) {
  return request({
    method: "POST",
    url: "/foreign/cart/createCart",
    data: qs.stringify(query),
  });
}
// 修改购物车
export function updateById(query) {
  return request({
    method: "POST",
    url: "/foreign/cart/updateById",
    data: qs.stringify(query),
  });
}
// 删除购物车
export function deleteById(query) {
  return request({
    method: "POST",
    url: "/foreign/cart/deleteById",
    data: qs.stringify(query),
  });
}

<template>
  <div v-if="imFrameSrc">
    <div class="im-page">
      <div class="im-panel-header">
        <!-- <img
          class="im-outside-icon"
          src="../../assets/images/benben_icon.png"
          @click="openIM"
        /> -->
        <i v-if="hasCode" class="iconfont icon-kefu" @click="openIM"></i>
        <!-- <span class="im-panel-title" v-show="imMsg">最近联系人</span>
        <span class="showIm-info" @click="showImMsg">
          <i class="showIm" :class="{ 'open-right': imMsg }"></i>
        </span> -->
      </div>
      <!-- <ul
        class="user-im-list"
        :class="{ userscroll: userscroll }"
        v-show="imMsg"
        ref="userImList"
      >
        <li
          class=""
          :class="{ 'serviceIM-none': !user.isOnline }"
          @click="imListopen(user.chatCode)"
          v-for="user in imsArr"
          :key="user.chatCode"
        >
          <img
            v-if="user.chatUserImage"
            class="userphoto-list"
            :src="user.chatUserImage"
          />
          <img
            v-else
            class="userphoto-list"
            src="https://apbenben.oss-cn-shenzhen.aliyuncs.com/group1/4BF15A9937214879B042AA7EC2F02466.jpg"
          />
          <span class="user-name">{{ user.chatUserName }}</span>
        </li>
      </ul> -->
      <el-dialog
        v-dialogDragWidth="{
          width: dialogW,
          height: dialogH,
          callback: resize,
          visible: visible,
        }"
        :visible.sync="IMVisible"
        :modal="false"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        :before-close="closeDialog"
        @opened="opened"
        ref="imDialog"
        class="imDialog"
        style="display: none"
      >
        <div slot="title">
          <div class="dialog-title-cont">
            <span class="red dialog-title">IM Assistant</span>
            <div class="title-cont text-c">&nbsp;&nbsp;&nbsp;</div>
          </div>
        </div>
        <iframe
          class="im-iframe"
          name="im-iframe"
          id="im-iframe"
          ref="imFrame"
          scrolling="no"
          allowtransparency="true"
          frameborder="0"
          style="width: 100%; height: 600px"
          :src="imFrameSrc"
        ></iframe>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { windowsNotify, windowsNotify2 } from "@/assets/plugins/isNotify";
import { on, off } from "@/assets/js/dom";
import { getStaffDetail } from "@/api/service/commonApi";
export default {
  name: "ims",
  props: {},
  data() {
    return {
      visible: false,
      dialogW: 1200, // 弹窗宽度
      dialogH: 640, // 弹窗高度
      imMsg: false,
      userscroll: false,
      userphotoUrl: "",
      imsArr: [], // 最近联系人数组
      checkMsgArr: [],
      orderArr: [],
      twinkleMessage: {
        time: 0,
        title: document.title,
        timer: null,
      },
      staffDetail: {
        code: "56A063FBE6454074AD739E8F6F0E4371",
      },
      hasCode: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo", "IMVisible", "imFrameSrc", "IMframe"]),
  },
  mounted() {
    this.$nextTick(() => {
      // this.getStaffDetail();
      setTimeout(() => {
        this.hasCode = true;
      }, 3000);
      this.setIMVisible(false);
      localStorage.setItem("twinkleStatus", 0); // 取消闪烁
      window.onmessage = (e) => {
        var dataArr = e.data.data;
        if (dataArr && dataArr.msgType) {
          var msgType = dataArr.msgType;
          // 判断用户是否在线
          try {
            if (msgType === "RC:StatusMsg") {
              var dataArray = JSON.parse(dataArr.arr);
              var userid = dataArray.userid;
              var status = parseInt(dataArray.status);
              var aDuUser = document.getElementsByClassName(`du-${userid}`);
              if (aDuUser.length > 0) {
                for (let i = 0; i < aDuUser.length; i++) {
                  let classVal = aDuUser[i].getAttribute("class");
                  let duParent = aDuUser[i].parentNode.parentNode;
                  let duTag = duParent ? duParent.getAttribute("dutag") : "";
                  if (status != null && status === 0) {
                    // 在线
                    if (classVal.indexOf("online") === -1)
                      aDuUser[i].setAttribute(
                        "class",
                        classVal.concat(" online")
                      );
                  } else {
                    // 不在线
                    aDuUser[i].setAttribute(
                      "class",
                      classVal.replace("online", "")
                    );
                  }
                  if (duTag) {
                    this.allimOnline(duParent, duTag);
                  }
                }
              }
            }
          } catch (err) {
            // console.log(err)
          }
        } else if (dataArr) {
          this.imsArr = dataArr;
          for (var i in dataArr) {
            let msgType = dataArr[i].msgType;
            var code = dataArr[i].chatCode;
            var content = dataArr[i].imDetailLatestDTO
              ? dataArr[i].imDetailLatestDTO.content
              : dataArr[i].content;
            var name = dataArr[i].chatUserName;
            if (
              !name ||
              name === "undefined" ||
              !content ||
              content === "undefined"
            ) {
              return;
            }
            if (msgType === "RC:CommunicationNtf") {
              content = "Inquiring price...";
              this.openIM();
            }
            if (dataArr[i].chatUserName) {
              name = dataArr[i].chatUserName.replace("|", "");
            }
            content = content.replace(/&nbsp;/g, "");
            if (content.indexOf("//////RC:TxtMsg//////") >= 0) {
              return;
            }
            if (
              code === "sysim" ||
              msgType === "RC:RefreshMsg" ||
              msgType === "RC:ContactNtf"
            ) {
              return;
            }
            if (content.indexOf("<div><br></div>") >= 0) {
              content = content.replace(/[<div><br></div>]*/g, "");
            } else if (content.indexOf("<div></div>") >= 0) {
              content = content.replace(/[<div></div>]*/g, "");
            }
            if (content.indexOf("<br>") >= 0) {
              content = content.replace(/<br>/g, "");
            }
            if (msgType === "RC:ImgMsg") {
              content = "[Picture]";
            }
            if (msgType === "RC:FileMsg") {
              content = "[File]";
            }
            if (msgType === "RC:CmdMsg") {
              // this.showOrderMsg(content);
              this.openIM();
            } else {
              this.showMsg(dataArr[i], name, content);
            }
          }
          if (this.imsArr) {
            this.imMsg = true;
            this.twinkleShow();
          }
          /* this.$nextTick(() => {
            this.userscroll = this.$refs.userImList.offsetHeight > 200;
          }); */
        }
      };
      on(window, "blur", this.windowBlur);
      on(window, "focus", this.windowFocus);
      window.addEventListener("keydown", this.quit);
    });
  },
  methods: {
    // getStaffDetail() {
    //   getStaffDetail({}).then((res) => {
    //     if (res.data.code === "200") {
    //       this.staffDetail = res.data.data;
    //     } else {
    //       this.$message.error(res.data.msg);
    //     }
    //     this.hasCode = true;
    //   });
    // },
    ...mapMutations({
      setIMVisible: "SET_IMVISIBLE",
    }),
    ...mapActions(["sendContactMsg"]),
    // 全部在线不在线
    allimOnline(ulNode, tag) {
      if (ulNode) {
        let aService = ulNode.getElementsByClassName("icon-service");
        if (aService.length > 0) {
          let hasOnline = false;
          for (let i = 0; i < aService.length; i++) {
            let serviceCls = aService[i].getAttribute("class");
            if (serviceCls.indexOf("online") !== -1) {
              hasOnline = true;
              break;
            }
          }
          var duTag = document.getElementsByClassName(tag);
          if (duTag.length > 0) {
            for (let i = 0; i < duTag.length; i++) {
              let tagCls = duTag[i].getAttribute("class");
              if (hasOnline) {
                if (tagCls.indexOf("online") === -1)
                  duTag[i].setAttribute("class", tagCls.concat(" online"));
              } else {
                duTag[i].setAttribute("class", tagCls.replace("online", ""));
              }
            }
          }
        }
      }
    },
    // 窗口拉伸
    resize(height) {
      let imDialog = this.$refs.imDialog.$refs.dialog;
      let imIframe = imDialog.getElementsByClassName("im-iframe")[0];
      imIframe.style.height = `${height - 40}px`;
      this.sendMessage(2, height - 40);
    },
    sendMessage(type, clientHeight) {
      // type：0 窗口关闭或者最小化传给即时聊天标注
      // type：1 窗口还原
      // type:2 icon点击按钮
      let dataJson = { data: { type: type, clientHeight: clientHeight } };
      window.frames[this.IMframe].postMessage(dataJson, "*");
      // let frame = document.getElementById('im-iframe')
      // frame.contentWindow.postMessage(dataJson, "*");
    },
    // 打开IM弹窗
    openIM() {
      // 联系客服
      /*      if (!this.userInfo.id) {
        return this.$message.error('用户未登录！')
      } */
      this.$store.dispatch("sendContactMsg", {
        portraitUri: this.staffDetail.portraitUri
          ? this.staffDetail.portraitUri
          : "https://apbenben.oss-cn-shenzhen.aliyuncs.com/group1/CF4AF382602141E7B4E1B262935D5F30.png",
        name: "BBmart-service",
        code: this.staffDetail.code || "56A063FBE6454074AD739E8F6F0E4371",
        remark: "BBmart-service",
        type: "1",
        isService: true,
      });
      // this.setIMVisible(true);
    },
    // 弹窗打开回调
    opened() {
      this.visible = this.IMVisible;
    },
    closeDialog() {
      this.setIMVisible(false);
      this.visible = this.IMVisible;
    },
    // 展开联系人列表
    showImMsg() {
      this.imMsg = !this.imMsg;
    },
    // 文字消息
    showMsg(data, name, content) {
      try {
        var sssss = data.chatCode + "#" + name + "#" + content;
        if (!this.checkMsgArr || this.checkMsgArr.length <= 0) {
          this.imListopen(data.chatCode);
          windowsNotify2(name + "：" + content, () => {
            this.imListopen(data.chatCode);
          });
          this.checkMsgArr.push(data.chatCode + "#" + name + "#" + content);
        } else {
          let b = 0;
          for (let i in this.checkMsgArr) {
            if (sssss === this.checkMsgArr[i]) {
              b = 1;
            }
          }
          if (b === 0) {
            this.imListopen(data.chatCode);
            windowsNotify2(name + "：" + content, () => {
              this.imListopen(data.chatCode);
            });
            this.checkMsgArr.push(data.chatCode + "#" + name + "#" + content);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    // 订单消息
    /* showOrderMsg(content) {
      try {
        let ccc = content.split("###");
        let type = ccc[2];
        let msg = ccc[0];
        let orderNo = ccc[1];
        if (this.orderArr.length === 0) {
          this.orderArr.push(orderNo);
          this.dowindowsNotify(orderNo, type, msg);
        } else {
          var b = 0;
          for (var i in this.orderArr) {
            if (orderNo === this.orderArr[i]) {
              b = 1;
            }
          }
          if (b === 0) {
            this.dowindowsNotify(orderNo, type, msg);
            this.orderArr.push(orderNo);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }, */
    // 订单消息系统通知
    /* dowindowsNotify (orderNo, type, msg) {
      if (type === '111') {
        windowsNotify(msg, () => {
          this.$router.push({
            name: 'sdetail',
            query: { no: orderNo }
          })
        })
      }
      if (type === '222') {
        windowsNotify(msg, () => {
          this.$router.push({
            name: 'pdetail',
            query: { no: orderNo }
          })
        })
      }
    }, */
    imListopen(code) {
      this.imsArr.forEach((item, index) => {
        if (code === item.chatCode) {
          let data = item;
          this.$delete(this.imsArr, index);
          if (data.chatUserName && data.chatUserName.indexOf("|") >= 0) {
            data.chatUserName = data.chatUserName.replace("|", "");
          }
          data.userType = item.type ? item.type : 1;
          data.type = 1;
          data.isService = true;
          this.sendContactMsg(data);
        }
      });
      this.imsArr = [];
    },
    // 显示新消息提示
    twinkleShow() {
      if (this.twinkleMessage.time === 0) {
        this.twinkleMessage.title = document.title;
      }
      let message = this.twinkleMessage;
      var title = message.title
        .replace("【　　　】", "")
        .replace("【You have new news】", "");
      // 定时器，设置消息切换频率闪烁效果就此产生
      this.twinkleMessage.timer = setTimeout(() => {
        if (localStorage.getItem("twinkleStatus") * 1 === 1) {
          message.time++;
          this.twinkleShow();
          if (message.time % 2 === 0) {
            document.title = "【You have new news】" + title;
          } else {
            document.title = "【　　　】" + title;
          }
        } else {
          this.twinkleClear();
        }
      }, 600);
      return [message.timer, message.title];
    },
    // 取消新消息提示
    twinkleClear() {
      clearTimeout(this.twinkleMessage.timer);
      this.twinkleMessage.time = 0;
      this.twinkleMessage.timer = null;
      document.title = this.twinkleMessage.title;
    },
    windowBlur() {
      localStorage.setItem("twinkleStatus", 1); // 设置开启闪烁
    },
    windowFocus() {
      localStorage.setItem("twinkleStatus", 0); // 取消闪烁
    },
    quit(e) {
      let key = e.keyCode;
      if (key === 27) {
        this.closeDialog();
      }
    },
  },
  beforeDestory() {
    window.removeEventListener("keydown", this.quit);
  },
  destroyed() {
    this.twinkleClear();
    off(window, "blur", this.windowBlur);
    off(window, "focus", this.windowFocus);
  },
};
</script>

<style lang="scss">
.im-page {
  /* position: fixed;
  right: 50px;
  bottom: 0;
  z-index: 99999999;
  border-radius: 2px;
  background-color: #fff; */
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__header {
    padding: 10px;
    border-bottom: 1px solid #ebeef5;
    .el-dialog__headerbtn {
      top: 15px;
    }
  }
  .imDialog {
    border: 1px solid #ebeef5;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    .el-dialog__headerbtn {
      top: 10px;
    }
    .el-icon-close {
      font-size: 25px;
    }
  }

  .im-panel-header {
    /* height: 36px;
    background-color: #d63740; */
    .icon-kefu {
      font-size: 36px;
      color: #ce413a;
      cursor: pointer;
    }
    .im-outside-icon {
      width: 36px;
      height: 36px;
      line-height: 36px;
      vertical-align: middle;
      cursor: pointer;
    }
    .im-panel-title {
      padding: 7px 20px;
      font-size: 12px;
      background-color: #fff;
    }
    .showIm-info {
      display: inline-block;
      height: 36px;
      line-height: 36px;
      cursor: pointer;
      .showIm {
        display: inline-block;
        width: 0;
        height: 0;
        border: 5px solid #d63740;
        border-left-color: #fff;
        border-right-color: #d63740;
        margin-left: 2px;
        margin-right: 0px;
        &.open-right {
          border-left-color: #d63740;
          border-right-color: #fff;
          margin-left: 0px;
          margin-right: 5px;
        }
      }
    }
  }
  .user-im-list {
    border: 1px solid #d63740;
    &.userscroll {
      overflow-y: scroll;
    }
    & > li {
      margin: 5px 0;
      cursor: pointer;
      overflow: hidden;
    }
    .userphoto,
    .userphoto-list {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      vertical-align: middle;
      margin: 0 5px;
    }
    .userphoto-list {
      float: left;
    }
    .user-name {
      width: 120px;
      height: 30px;
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      font-size: 12px;
      float: left;
    }
  }
}
.dialog-title-cont {
  position: relative;
  .dialog-title {
    position: absolute;
    left: 0;
  }
  .title-cont {
    a {
      color: #008af0;
    }
  }
}
</style>

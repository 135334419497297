import axios from 'axios';
import { Message, Loading } from 'element-ui';

//默认调用对应环境的接口地址
let root = process.env.VUE_APP_BASE_URL;
axios.defaults.baseURL = root;
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
axios.interceptors.request.use(config => {
  const token = window.sessionStorage.getItem('accessToken');
  if (token) {
    config.headers.accessToken = token;
  }
  return config
}, error => {
  return Promise.reject(error);
});

let loading = null;//保存加载
let i = 0;//记录请求次数

//打开加载
function openLoad(lock) {
  let loadSet = Loading.service({
    lock: lock,
    text: '加载中……',
    body: true,
    background: 'rgba(0, 0, 0, 0)'
  });
  return Loading.service(loadSet);
}

//关闭加载
function closeLoad() {
  i--;//请求执行完，请求次数减1
  if(i < 1) {
    i = 0;
    //请求次数为0表示最后一个请求执行完，关闭加载
    loading ? loading.close() : null;
  }
}

export default {
  request:root,
  get: function(url, params) {
    return new Promise((resolve, reject) => {
      i++;
      loading = openLoad(true);
      axios.get(url, params).then(res => {
        closeLoad();
        resolve(res.data);
      }).catch(error => {
        closeLoad();
        reject(error.data);
      })
    })
  },
  post: function(url, params) {
    return new Promise((resolve, reject) => {
      i++;
      loading = openLoad(true);
      axios.post(url, params).then(res => {
        closeLoad();
        resolve(res.data);
      }).catch(error => {
        closeLoad();
        reject(error.data);
      })
    })
  },
}
import axios from "axios";
import router from "../router/index";
import commonUrl from "@/common/commonurl";
import { Message } from "element-ui";
// 创建axios实例
const service = axios.create({
  timeout: 300000, // 请求超时时间
});

service.defaults.retry = 3;
service.defaults.retryDelay = 1000;
service.defaults.baseURL = "/api";
service.defaults.withCredentials = true; // 允许axios请求携带cookie等凭证

// request 请求拦截器
service.interceptors.request.use((config) => {
  const token = window.sessionStorage.getItem("accessToken");
  if (token && config.baseURL == "/api") {
    config.headers.accessToken = token;
  }
  switch (config.baseURL) {
    case "/api":
      config.baseURL = commonUrl.baseurl;
      break;
    case "/benben-dubbo-im":
      config.baseURL = commonUrl.imBaseurl;
      break;
  }
  return config;
});
// response 响应拦截器
service.interceptors.response.use(
  (response) => {
    let config = response.config;
    resCodeAuth(config, response);
    return response;
  },
  (error) => {
    if (!axios.isCancel(error)) {
      Message.error("操作失败！");
    }
    return Promise.reject(error);
  }
);
/**
 * 请求响应状态码判断
 * @param {Object} currentRoute 路由
 * @param {Object} response 响应数据
 */
export function resCodeAuth(config, response) {
  let INTA = false;
  let resCode =
    response.data && response.data.bizCode ? response.data.bizCode.code : "0";
  let currentRoute = router.currentRoute; // 当前路由
  /* whiteList 免登陆白名单 */
  if (
    resCode === "3" &&
    config.url.indexOf("/foreign/user/getOwerUser") === -1 &&
    (!currentRoute.meta || !currentRoute.meta.whiteList)
  ) {
    INTA = true;
    router.push({
      path: "/login",
      query: { redirectUrl: encodeURIComponent(window.location.href) },
    });
  }
  return INTA;
}
export default service;

<template>
  <div class="sideBar-box">
    <div class="sideBar-item">
      <div class="cart-box">
        <router-link to="/cart">
          <i class="iconfont icon-gouwuche"></i>
          <div class="item-count">{{ cardCount }}</div>
        </router-link>
      </div>
      <div class="kefu-box">
        <ims ref="ims" v-if="userInfo && userInfo.id"></ims>
      </div>
    </div>
  </div>
</template>

<script>
import Ims from "@/components/ims/ims";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    Ims,
  },
  computed: {
    cardCount() {
      return this.$store.state.cartCount;
    },
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      staffDetail: {},
    };
  },
  mounted() {
    let that = this;
    this.saveUserInfo();
    this.getCartList();
    window.addEventListener("message", function (e) {
      let data = e.data;
      if (typeof data === "string") {
        try {
          // 尝试将字符串解析为JSON
          let parsedData = JSON.parse(data);
          if (
            parsedData.from === "bbMartCart" &&
            parsedData.event === "bbMartCart"
          ) {
            that.getCartList();
          }
        } catch (error) {
          // console.error("error:", error);
        }
      }
    });
  },
  methods: {
    ...mapActions(["saveUserInfo", "getCartList"]),
  },
};
</script>

<style lang="scss" scoped>
.sideBar-box {
  position: fixed;
  right: 15px;
  top: 350px;
  z-index: 99;

  .sideBar-item {
    width: 36px;
    text-align: right;
  }

  .cart-box {
    position: relative;
    margin-bottom: 30px;

    .item-count {
      background: #d54029;
      color: #ffffff;
      position: absolute;
      top: -15px;
      right: -5px;
      width: 25px;
      height: 25px;
      line-height: 25px;
      border-radius: 50%;
      font-size: 14px;
      text-align: center;
    }

    .icon-gouwuche {
      font-size: 36px;
      color: #ce413a;
      cursor: pointer;
    }
  }
}
</style>

import Vue from "vue";
import Vuex from "vuex";
import UserInfo from "./modules/user";
import IMframe from "./modules/IMframe";
import getters from "./getters";
import { getCartList } from "@/api/service/commonApi";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cartCount: 0,
    cartList: [],
  },
  mutations: {
    setCartCount(state, res) {
      state.cartCount = res;
    },
    addCartCount(state, res) {
      // state.cartCount++;
    },
    reduceCartCount(state, res) {
      // state.cartCount--;
    },
  },
  actions: {
    // 购物车数量
    getCartList: ({ state }) => {
      return new Promise((resolve, reject) => {
        getCartList({}).then(
          (response) => {
            let data = response.data;
            if (response.data.bizCode.code === "0") {
              let hasRes = data.result && data.result.length > 0;
              state.cartCount = hasRes ? data.result.length : 0;
              let cartList = hasRes ? data.result : [];
              cartList.forEach((item) => {
                item.imgUrl = "";
                item.imageList = [];
                if (item.image) {
                  item.imageList = item.image
                    .split(",")
                    .filter((item) => item.trim() !== "");
                  item.imgUrl = item.imageList[0];
                }
              });
              state.cartList = cartList;
            }
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
  },
  modules: {
    UserInfo,
    IMframe,
  },
  getters,
});

export default class Utils {
  constructor() {}

  /**
  * @desc 函数防抖
  * @param func 目标函数
  * @param wait 延迟执行毫秒数
  */
  static debounce(fn, gapTime) {
    let _lastTime
    return function () {
        clearTimeout(_lastTime)
        _lastTime = setTimeout(() => {
            fn.apply(this, arguments)
        }, gapTime)
    }
  }

  /**
  * @desc 函数节流
  * @param func 函数
  * @param wait 延迟执行毫秒数
  */
  static throttle(fn, gapTime) {
    if (gapTime == null || gapTime == undefined) {
      gapTime = 1500
    }
    let _lastTime = null
    // 返回新的函数
    return function () {
      let _nowTime = +new Date()
      if (_nowTime - _lastTime > gapTime || !_lastTime) {
          fn.apply(this, arguments) //将this和参数传给原函数
          _lastTime = _nowTime
      }
    }
  }

  /** 除 */
  static accDiv(arg1=0, arg2=0) {
    var t1 = 0,
      t2 = 0,
      r1,
      r2;
    try {
      t1 = arg1.toString().split('.')[1].length;
    } catch (e) {}
    try {
      t2 = arg2.toString().split('.')[1].length;
    } catch (e) {}
    r1 = Number(arg1.toString().replace('.', ''));
    r2 = Number(arg2.toString().replace('.', ''));
    return (r1 / r2) * Math.pow(10, t2 - t1);
  }

  /** 乘 */
  static accMul(arg1=0, arg2=0) {
    var m = 0,
      s1 = arg1.toString(),
      s2 = arg2.toString();
    try {
      m += s1.split('.')[1].length;
    } catch (e) {}
    try {
      m += s2.split('.')[1].length;
    } catch (e) {}
    return (
      (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
      Math.pow(10, m)
    );
  }

  /** 加 */
  static accAdd(arg1=0, arg2=0) {
    var r1, r2, m;
    r1 = toInt(arg1);
    r2 = toInt(arg2);
    return (r1 + r2) / Math.pow(10, 2);
  }

  /** 减 */
  static accMin(arg1=0, arg2=0) {
    var r1, r2;
    r1 = toInt(arg1);
    r2 = toInt(arg2);

    return (r1 - r2) / Math.pow(10, 2);
  }

  /** 保留两位小数 */
  static keepTwoDecimalFull(num){
    let result = parseFloat(num);
    if (isNaN(result)) {
      // alert('传递参数错误，请检查！');
      return 0;
    }
    result = Math.round(num * 100) / 100;
    let s_x = result.toString();
    let pos_decimal = s_x.indexOf('.');
    if (pos_decimal < 0) {
      pos_decimal = s_x.length;
      s_x += '.';
    }
    while (s_x.length <= pos_decimal + 2) {
      s_x += '0';
    }
    return s_x;
  }
}
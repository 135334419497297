const NODE_ENV = process.env.NODE_ENV;
const baseUrlMaps = {
  // 本地
  development: {
    baseurl: "/api",
    imBaseurl: "/benben-dubbo-im", // im接口前缀
    imurl: "http://192.168.0.193:8080",
    // imurl: "http://127.0.0.1:8080",
  },
  // 测试
  test: {
    baseurl: "http://test.erp.apware.cn/api",
    imBaseurl: "https://tapi.apbenben.com/benben-dubbo-im",
    imurl: "https://sit.apbenben.com",
  },
  // 生产
  production: {
    baseurl: "https://benben.erp.apware.cn/api",
    imBaseurl: "https://api.apbenben.com/benben-dubbo-im",
    imurl: "https://im.apbenben.com",
  },
};
export default baseUrlMaps[NODE_ENV];

// import Notify from "@wcjiang/notify"
// import commonUrl from '../../common/commonurl'

// 桌面通知(兼容360)
function windowsNotify360(strNewsContent, callback, b) {
  if (
    window.webkitNotifications &&
    window.webkitNotifications.checkPermission() === 0
  ) {
    var notification = window.webkitNotifications.createNotification(
      "https://www.apbenben.com/resources/images/icon/icon16x16.ico",
      "BBmart",
      strNewsContent
    );
    notification.onshow = function () {
      setTimeout(function () {
        notification.close();
      }, 5000);
    };
    notification.onclose = function () {
      if (b < 2) {
        b = b + 1;
        windowsNotify360(strNewsContent, callback, b);
      }
    };
    // 下面是定义点击事件，类似地还可定义其它事件
    notification.onclick = function () {
      notification.close();
      window.focus();
      if (callback) {
        callback();
      }
      try {
        notification.close();
        // notification.cancel();
      } catch (e) {
        notification.close();
      }
    };
    // 弹出
    // notify.show();
  } else if (window.webkitNotifications) {
    window.webkitNotifications.requestPermission(windowsNotify360);
  }
}

// 桌面通知(兼容火狐、谷歌)
function windowsNotifyFFAndGE(strNewsContent, callback, b) {
  var notification = new Notification("BBmart", {
    body: strNewsContent,
    icon: "https://www.apbenben.com/resources/images/icon/icon16x16.ico",
  });
  notification.onshow = function () {
    setTimeout(function () {
      notification.close();
    }, 6000);
  };
  notification.onclose = function () {
    if (b < 2) {
      b = b + 1;
      windowsNotifyFFAndGE(strNewsContent, callback, b);
    }
  };
  // 下面是定义点击事件，类似地还可定义其它事件
  notification.onclick = function () {
    notification.close();
    window.focus();
    if (callback) {
      callback();
    }
    try {
      notification.close();
      // notification.cancel();
    } catch (e) {
      notification.close();
    }
  };
  // console.log(notification);
}

// 桌面通知(兼容360)
function windowsNotify3602(strNewsContent, callback) {
  if (
    window.webkitNotifications &&
    window.webkitNotifications.checkPermission() === 0
  ) {
    var notification = window.webkitNotifications.createNotification(
      "https://www.apbenben.com/resources/images/icon/icon16x16.ico",
      "BBmart",
      strNewsContent
    );
    notification.onshow = function () {
      setTimeout(function () {
        notification.close();
      }, 6000);
    };
    notification.onclose = function () {
      try {
        notification.close();
        notification.cancel();
      } catch (e) {
        notification.close();
      }
    };
    // 下面是定义点击事件，类似地还可定义其它事件
    notification.onclick = function () {
      notification.close();
      window.focus();
      if (callback) {
        callback();
      }
      try {
        notification.close();
        // notification.cancel();
      } catch (e) {
        notification.close();
      }
    };
    // 弹出
    // notify.show();
  } else if (window.webkitNotifications) {
    window.webkitNotifications.requestPermission(windowsNotify360);
  }
}

// 桌面通知(兼容火狐、谷歌)
function windowsNotifyFFAndGE2(strNewsContent, callback) {
  var notification = new Notification("BBmart", {
    body: strNewsContent,
    icon: "https://www.apbenben.com/resources/images/icon/icon16x16.ico",
  });
  notification.onshow = function () {
    setTimeout(function () {
      notification.close();
    }, 6000);
  };
  notification.onclose = function () {
    try {
      notification.close();
      // notification.cancel();
    } catch (e) {
      notification.close();
    }
  };
  // 下面是定义点击事件，类似地还可定义其它事件
  notification.onclick = function () {
    notification.close();
    window.focus();
    if (callback) {
      callback();
    }
    try {
      notification.close();
      // notification.cancel();
    } catch (e) {
      notification.close();
    }
  };
}

/*
 * 桌面通知
 * strNewsContent:通知的内容
 */
export function windowsNotify(strNewsContent, callback) {
  if (
    !("Notification" in window) &&
    !window.webkitNotifications &&
    window.webkitNotifications.checkPermission() !== 0
  ) {
    return;
  }

  if (
    Notification.permission == null ||
    Notification.permission === undefined
  ) {
    windowsNotify360(strNewsContent, callback, 0);
  } else if (Notification.permission === "granted") {
    windowsNotifyFFAndGE(strNewsContent, callback, 0);
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission(function (permission) {
      if (!("permission" in Notification)) {
        Notification.permission = permission;
      }
      if (permission === "granted") {
        windowsNotifyFFAndGE(strNewsContent, callback, 0);
      }
    });
  }
}
/*
 * 桌面通知
 * strNewsContent:通知的内容
 */
export function windowsNotify2(strNewsContent, callback) {
  // console.log(strNewsContent)
  if (
    !("Notification" in window) &&
    !window.webkitNotifications &&
    window.webkitNotifications.checkPermission() !== 0
  ) {
    return;
  }

  if (
    Notification.permission == null ||
    Notification.permission === undefined
  ) {
    // console.log(strNewsContent)
    windowsNotify3602(strNewsContent, callback);
  } else if (Notification.permission === "granted") {
    // console.log(strNewsContent)
    windowsNotifyFFAndGE2(strNewsContent, callback);
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission(function (permission) {
      if (!("permission" in Notification)) {
        Notification.permission = permission;
      }
      if (permission === "granted") {
        windowsNotifyFFAndGE2(strNewsContent, callback);
      }
    });
  }
}
